export const RoutePaths = {
  default: '/',
  home: '/home',
  login: '/login',
  confirmpassword: '/confirmpassword',
  signup: '/signup',
  users: 'users',
  groups: 'groups',
  roles: 'roles',
  permissions: 'permissions',
  homeUrl: '/home/*',
  userDetail: 'users/:id',
  addUser: 'users/add',
  editUser: 'users/add/:id',
  addGroup: 'groups/add',
  editGroup: 'groups/edit/:id',
  addRole: 'roles/add',
  editRole: 'roles/edit/:id',
  usersUrl: '/home/users',
  groupsUrl: '/home/groups',
  rolesUrl: '/home/roles'
};
