export const ErrorMessagesArray = [
  'Forbidden resource',
  'jwt expired',
  'Unauthorized',
  'Unauthotized user tried to access page'
];

export enum FilterConditions {
  EQUALS = 'EQUALS',
  IN = 'IN'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
